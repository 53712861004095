import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SAVE0000 from '../../images/SAVE0000.jpeg';
import SAVE0001 from '../../images/SAVE0001.jpeg';
import SAVE0002 from '../../images/SAVE0002.jpeg';
import SAVE0003 from '../../images/SAVE0003.jpeg';
export const _frontmatter = {
  "title": "Fan Art",
  "path": "/fanart.html"
};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <div className="text-center">{children}</div>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Instructions are simple. Select the pic to see it. You could save them by right mouse clicking the pic and "Save As" or "Save Target As".`}</p>
    <p>{`By:Tarik Hamilton age:10 made in: Janurary 2002 `}<a href={SAVE0002}>{`SSJ3 Gokou`}</a></p>
    <p>{`By:Tarik Hamilton age:10 made in 2001 `}<a href={SAVE0001}>{`SSJ2 Majin Vegita`}</a></p>
    <p>{`By:Tarik Hamilton age:10 made in: Feburary 2002 `}<a href={SAVE0000}>{`SSJ3 Crontee`}</a>{` (Made up character)`}</p>
    <p>{`By:Tarik Hamilton age:10 made in: Feburary 2002 `}<a href={SAVE0003}>{`Crontee`}</a>{` (Made up character)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      